/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */
/*
Component to be used as wrapper for M2 productpage tabs
```html
<m2-product-data-tabs>
  <slot name="tabs" slot="tabs"></slot>
  <slot name="tabpanels" slot="tabpanels"></slot>
</m2-product-data-tabs>
```

M2 template should be structured as follows:
```html
<div slot="tabs">
  <div class="product data items" id="<?php echo $alias;?>">
    <div class="data item title">
      <?= $label ?>
    </div>
  </div>
</div>
<div slot="tabpanels" class="data item content">
  <?= $html ?>
</div>

<div slot="tabs">
  <div class="product data items" id="<?php echo $alias;?>" role="tab">
    <div class="data item title">
      <?= $label ?>
    </div>
  </div>
</div>
<div slot="tabpanels" class="data item content">
  <?= $html ?>
</div>
```
*/
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js'
import { addListener } from '@polymer/polymer/lib/utils/gestures'
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status.js'
import { LERM } from '@reachdigital/lightdom-elems-retreiver-mixin/lightdom-elems-retreiver-mixin.js'
import '@polymer/paper-tabs/paper-tabs.js'
import '@polymer/paper-tabs/paper-tab.js'
import '@polymer/iron-pages/iron-pages.js'
import '@reachdigital/inner-html/inner-html.js'
import { isMobile } from '@reachdigital/utils/is-mobile'
import { DomIf } from '@polymer/polymer/lib/elements/dom-if.js'
import { beforeNextRender } from '@polymer/polymer/lib/utils/render-status'

class ShopProductDataTabs extends LERM(PolymerElement) {
  static get template() {
    return html`
      <style>
        :host {
          display: block;
          overflow: hidden;
          position: relative;
        }
        .additional-attributes,
        .table-caption {
          text-align: left;
        }
        iron-pages {
          margin-top: var(--spacing-lg);
        }
        paper-tabs {
          margin-left: calc((var(--spacing-xs) * 1.3) * -1);
          margin-right: calc((var(--spacing-xs) * 1.3) * -1);
        }
        paper-tab {
          padding: 0;
          margin: 0 calc(var(--spacing-xs) * 1.3);
        }
        paper-tab span {
          font-size: var(--m2-product-data-tabs__paper-tab--font-size, 14px);
          font-weight: var(--m2-product-data-tabs__paper-tab--font-weight, normal);
          font-family: var(--m2-product-data-tabs__paper-tab--font-family, inherit);
          font-style: normal;
          line-height: var(--m2-product-data-tabs__paper-tab--line-height, normal);
          letter-spacing: var(--m2-product-data-tabs__paper-tab--letter-spacing, 1.4px);
          color: var(--m2-product-data-tabs__paper-tab--color, var(--base-contrast));
          text-transform: var(--m2-product-data-tabs__paper-tab--text-transform, uppercase);
        }

        paper-tabs {
          position: relative;
          height: calc(48px + 7px);

          /* Override inline touch action which prevents scrolling (on mobile) on paper-tabs*/
          touch-action: auto !important;
        }
        paper-tabs::before {
          position: absolute;
          content: '';
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: var(--base-contrast);
          opacity: 0.1;
        }
        paper-tab {
          margin-top: -7px;
        }
        paper-tab span {
          opacity: 0.6;
          color: var(--base-contrast);
          font-size: var(--font-size-base);
          font-weight: 500;
          letter-spacing: -0.25px;
          line-height: var(--line-height-base);
        }
        paper-tab.iron-selected span {
          opacity: 0.8;
        }

        @media (min-width: 768px) {
          iron-pages {
            height: var(--__shop-product-data-tabs__height, auto);
          }
        }
      </style>
      <template is="dom-if" if="[[isMobile]]">
        <paper-tabs selected="{{selected}}">
          <template is="dom-repeat" items="[[lightDomElems.tabs]]">
            <paper-tab noink id$="[[item.getAttribute('id')]]">
              <span>[[item.textContent]]</span>
            </paper-tab>
          </template>
        </paper-tabs>
      </template>
      <template is="dom-if" if="[[!isMobile]]">
        <paper-tabs scrollable hide-scroll-buttons selected="{{selected}}">
          <template is="dom-repeat" items="[[lightDomElems.tabs]]">
            <paper-tab noink id$="[[item.getAttribute('id')]]">
              <span>[[item.textContent]]</span>
            </paper-tab>
          </template>
        </paper-tabs>
      </template>
      <iron-pages selected="[[selected]]">
        <slot name="tabpanels"></slot>
      </iron-pages>
    `
  }

  static get is() {
    return 'shop-product-data-tabs'
  }

  static get properties() {
    return {
      targets: {
        value: [
          {
            key: 'tabs',
            selector: '.product.data.items .item.title',
          },
          {
            key: 'tabpanels',
            selector: '.product.data.items .item.content',
          },
        ],
      },
      selected: {
        type: Number,
        value: 0,
        observer: '_selectedChanged',
      },
      _reviewsTabLoaded: {
        type: Boolean,
        value: false,
      },
    }
  }

  constructor() {
    super()
    this.childSizes = []
  }

  connectedCallback() {
    super.connectedCallback()
    this.isMobile = isMobile((e) => (this.isMobile = e.matches))

    beforeNextRender(this, () => {
      this.__resizebserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => this.handleResize(entry))
      })

      const flattenedElems = FlattenedNodesObserver.getFlattenedNodes(this).filter(
        (node) => node.nodeType === Node.ELEMENT_NODE,
      )
      flattenedElems
        .filter((element) => element.getAttribute('slot') === 'tabpanels')
        .forEach((element) => {
          this.__resizebserver.observe(element)
        })
    })

    setTimeout(() => {
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(
          () => {
            this.loadReviews()
          },
          { timeout: 1000 },
        )
      } else {
        afterNextRender(this, (_) => {
          this.loadReviews()
        })
      }
    }, 10000)
  }

  handleResize(entry) {
    if (this.isMobile) {
      return
    }
    const childInex = [...entry.target.parentElement.children].indexOf(entry.target)
    const childHeight = entry.contentRect.top + entry.contentRect.bottom
    if (childHeight === 0) {
      return
    }
    this.childSizes[childInex] = childHeight
    this.highestHeight = Math.max(...Object.values(this.childSizes))
    this.style.setProperty('--__shop-product-data-tabs__height', `${this.highestHeight}px`)
  }

  selectTabById(id) {
    const flattenedElems = FlattenedNodesObserver.getFlattenedNodes(this).filter(
      (node) => node.nodeType === Node.ELEMENT_NODE,
    )
    const tabToSelect = flattenedElems.filter(
      (node) =>
        !![...node.childNodes]
          .filter((node) => node.nodeType === Node.ELEMENT_NODE)
          .filter((node) => node.id === id).length,
    )
    if (!tabToSelect.length) {
      console.warn('Tab not found!', this)
      return
    }
    const tabs = flattenedElems.filter((node) => node.getAttribute('slot') === 'tabs')
    this.selected = tabs.findIndex((tab) => tab.isSameNode(tabToSelect[0]))
  }

  scrollToTabById(id) {
    afterNextRender(this, () => {
      this.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
    setTimeout(() => {
      this.selectTabById(id)
    }, 300)
  }

  loadReviews() {
    if (typeof jQuery === 'undefined' || this._reviewsTabLoaded) {
      return
    }

    let reviewsTab = FlattenedNodesObserver.getFlattenedNodes(this).filter((node) => {
      return (
        node.nodeType === Node.ELEMENT_NODE &&
        node.slot === 'tabs' &&
        node.firstElementChild &&
        node.firstElementChild.id === 'reviews'
      )
    })
    reviewsTab = reviewsTab.length ? reviewsTab[0].firstElementChild : null

    // Magento reviews requires "beforeOpen" jQuery trigger to load reviews
    // Don't forget to set proper selector "reviewsTabSelector" in review template ("#reviews.product.data")
    this._reviewsTabLoaded = true

    if (!reviewsTab) {
      // No reviews tab available, probably disabled in Magento
      return
    }
    jQuery(reviewsTab).trigger('beforeOpen')
  }

  _selectedChanged(selected) {
    if (!selected) {
      return
    }
    this.loadReviews()
  }
}
customElements.define('shop-product-data-tabs', ShopProductDataTabs)
