/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */
import { LitElement, html } from 'lit-element'
import { beforeNextRender } from '@polymer/polymer/lib/utils/render-status.js'
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js'
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js'

class ProductUsps extends LitElement {
  render() {
    // lanuage=HTML
    return html`
      <style>
        :host {
          color: var(--product-usps-color, var(--base-contrast-muted, black));
          margin-top: var(--product-usps-margin-top, 0);
          margin-bottom: var(--product-usps-margin-bottom, var(--spacing-md));
          --iron-icon-width: 18px;
          --iron-icon-height: 18px;
          display: block;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: -0.1px;
          line-height: 24px;
        }
        :host > div {
          display: var(--product-usps-display, flex);
          flex-direction: var(--product-usps-flex-direction, column);
          flex-wrap: nowrap;
          justify-content: var(--product-usps-justify-content, space-between);
          padding: 0;
          margin: 0;
        }
        .usp:not(:last-child) {
          margin-bottom: calc(var(--spacing-xs) * 0.75);
        }
        div > div {
          list-style: none;
          white-space: normal;
        }

        a {
          color: var(--primary);
        }
        iron-icon {
          color: var(--primary);
          margin-right: calc(var(--spacing-xs) * 0.65);
        }
        span {
          margin-left: 0.2em;
        }
        @media (max-width: 1200px) {
          span {
            display: inline-grid;
          }
        }
      </style>

      <div>
        ${this.usps.map((usp) => {
          return html`
            <div class="usp">
              <iron-icon icon="check"></iron-icon> <span>${unsafeHTML(usp)}</span>
            </div>
          `
        })}
      </div>
    `
  }

  constructor() {
    super()
    this.usps = []
  }

  static get properties() {
    return {
      /**
       * @type {array}
       */
      usps: Array,
    }
  }

  /* Called when the element is inserted into a document */
  connectedCallback() {
    super.connectedCallback()

    beforeNextRender(this, () => {
      const nodes = FlattenedNodesObserver.getFlattenedNodes(this).filter(
        (node) => node.nodeType === Node.ELEMENT_NODE && node.id !== 'ct-field-',
      )
      if (!nodes.length) {
        return
      }

      nodes.forEach((node) => {
        if (!node.classList.contains('ct-field-repeater_fields')) {
          return
        }
        this.usps = [...node.querySelectorAll('div.ct-view-usp_repeater_field')]
          .map((element) =>
            element.querySelector('.ct-field-area p')
              ? element.querySelector('.ct-field-area p').innerHTML
              : element.querySelector('.ct-field-area').innerHTML,
          )
          .filter((element) => element)
      })
    })
  }
}

customElements.define('product-usps', ProductUsps)
