/*
  ~ Copyright © Reach Digital (https://www.reachdigital.io/)
  ~ See LICENSE.txt for license details.
  */
import { LitElement, html, css } from 'lit-element'
import '@reachdigital/m2-container/m2-container'
import '@reachdigital/icons/shop-icons'
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status'
import { debounce } from '@reachdigital/shop-utils/debounce'
import {
  initializeHeaderThemes,
  getShopHeaderTheme,
  setShopHeaderTheme,
} from '@reachdigital/shop-header/headerTheme'
import { openMegaMenu, closeMegaMenu } from '@reachdigital/mega-menu/triggers'
import '@reachdigital/m2-cart/m2-minicart-count-badge'
import { translateDirective as t } from '@reachdigital/m2-translate-directive/m2-translate-directive'

class ShopHeader extends LitElement {
  static get styles() {
    // language=CSS
    return css`
      :host {
        position: relative;
        z-index: 10;
        display: block;
        width: 100%;

        --iron-icon-width: 32px;
        --iron-icon-height: 32px;
      }
      header {
        position: fixed;
        width: 100%;
      }
      .top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        max-width: var(--shop-header-top-row-width, var(--m2-container-width));
        height: var(--shop-header-top-row-height, 65px);
        margin: 0 auto;
        padding: 0 var(--shop-header-top-row-padding-horizontal, var(--m2-container-padding));
      }
      .nav-icon {
        /* compensate icon viewbox */
        transform: translateX(-6px);
        cursor: pointer;
      }
      .home-link,
      .home-link-light {
        display: inline-flex;
      }
      .logo {
        width: 130px;
        margin-top: 3px;
      }
      .cart-link {
        position: relative;
        margin-bottom: 1px;
        color: inherit;

        /* compensate icon viewbox */
        transform: translateX(6px);
      }
      m2-minicart-count-badge {
        position: absolute;
        top: var(--m2-minicart-dropdown-count-badge-top, 7px);
        right: var(--m2-minicart-dropdown-count-badge-right, 14px);
        z-index: 1;
      }
      .scroll-detect-pixel {
        position: absolute;
        z-index: 1;
        width: 1px;
        height: 1px;
        pointer-events: none;
      }

      /* Scroll animation */
      :host([bg-scroll-in]) header::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: white;
        border-bottom: solid 1px #ddd;
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.25s ease-out, opacity 0.25s ease-out;
        content: '';
      }
      :host(.prepare-animation) header::before {
        will-change: opacity, transform;
      }
      :host([is-scrolling]) header::before {
        transform: translateY(0%);
        opacity: 1;
      }

      /* Themes */

      :host([applying-theme]) header::before {
        /* transition: none; */
      }

      /* default - white bg - dark content */
      :host([theme='default']) header::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: white;
        border-bottom: solid 1px #ddd;
        transform: translateY(0);
        opacity: 1;
        transition: none;
        content: '';
      }

      /* transparent-dark - transparent bg - dark content */
      :host([theme='transparent-dark']) header::before {
      }

      /* transparent-dark - transparent bg - dark content */
      :host([theme='transparent-light']) header {
        transition: color 0s linear 0.2s;
      }

      :host([theme='transparent-light']:not([is-scrolling])) header {
        color: var(--base);
      }

      :host([theme='transparent-light']) header::before {
      }
      :host([theme='transparent-light']) .home-link {
        position: absolute;
        top: 50%;
        left: calc(50% - (130px / 2));
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0s linear 0s;
      }

      :host([theme='transparent-light'][is-scrolling]) .home-link {
        transition: opacity 0s linear 0.2s;
      }

      :host([theme='transparent-light']) .home-link-light {
        transition: opacity 0s linear 0s;
      }

      :host([theme='transparent-light'][is-scrolling]) .home-link-light {
        opacity: 0;
        transition: opacity 0s linear 0.2s;
      }

      :host([theme='transparent-light'][is-scrolling]) .home-link {
        opacity: 1;
      }

      /* checkout - default, without menu/cart icons, adds continue shopping btn */
      :host([theme='checkout']) header::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: white;
        border-bottom: solid 1px #ddd;
        transform: translateY(0);
        opacity: 1;
        transition: none;
        content: '';
      }
      :host([theme='checkout']) .top-row {
        position: relative;
        justify-content: center;
      }
      .continue {
        position: absolute;
        top: 50%;
        left: var(--m2-container-padding);
        color: var(--base-contrast-muted);
        font-weight: 500;
        font-size: 16px;
        font-family: var(--font-family-base);
        line-height: 25px;
        letter-spacing: -0.1px;
        text-decoration: none;
        transform: translateY(-50%);
      }
      .continue iron-icon {
        width: 25px;
        height: 25px;
        margin-left: -7px;
        color: #a6a6a6;
      }
      .continue iron-icon.mobile-icon {
        width: 32px;
        height: 32px;
      }
      @media (max-width: 767px) {
        .continue .desktop-icon,
        .continue span {
          display: none;
        }
      }
      @media (min-width: 768px) {
        .continue .mobile-icon {
          display: none;
        }
      }
    `
  }

  render() {
    return html`
      <div class="scroll-detect-pixel"></div>
      <header>
        <div class="top-row">
          ${this.theme === 'checkout'
            ? html`
                <a class="continue" href="${window.ReachDigitalGlobals.baseUrl}">
                  <iron-icon class="desktop-icon" icon="shop:pointer-left"></iron-icon>
                  <iron-icon class="mobile-icon" icon="shop:close"></iron-icon>
                  <span>${t('Continue shopping')}</span></a
                >
                <a class="home-link" href="${window.ReachDigitalGlobals.baseUrl}"
                  ><img class="logo" src="${window.ReachDigitalGlobals.logoDarkSrc}"
                /></a>
              `
            : html`
                ${this.open
                  ? html`
                      <iron-icon
                        class="nav-icon"
                        icon="shop:close"
                        @click="${(e) => {
                          this.handleCloseNav(e)
                        }}"
                      ></iron-icon>
                    `
                  : html`
                      <iron-icon
                        class="nav-icon"
                        icon="shop:nav"
                        @click="${(e) => {
                          this.handleOpenNav(e)
                        }}"
                      ></iron-icon>
                    `}
                <a class="home-link" href="${window.ReachDigitalGlobals.baseUrl}"
                  ><img class="logo" src="${window.ReachDigitalGlobals.logoDarkSrc}"
                /></a>
                ${this.theme === 'transparent-light'
                  ? html`
                      <a class="home-link-light" href="${window.ReachDigitalGlobals.baseUrl}"
                        ><img class="logo" src="${window.ReachDigitalGlobals.logoLightSrc}"
                      /></a>
                    `
                  : ''}
                <a class="cart-link" href="${this.cartUrl}">
                  <m2-minicart-count-badge></m2-minicart-count-badge>
                  <iron-icon icon="${this.cartIcon}" slot="button"></iron-icon>
                </a>
              `}
        </div>
      </header>
    `
  }

  static get properties() {
    return {
      cartIcon: {
        type: String,
      },
      isScrolling: {
        type: Boolean,
        attribute: 'is-scrolling',
        reflect: true,
      },
      // @todo functionality overlap with bgScrollIn?
      disableIsScrolling: {
        type: Boolean,
        attribute: 'disable-is-scrolling',
      },
      intersectionObserverInitialized: {
        type: Boolean,
      },
      cartUrl: {
        type: String,
      },
      theme: {
        type: String,
        attribute: true,
        reflect: true,
      },
      bgScrollIn: {
        type: Boolean,
        attribute: 'bg-scroll-in',
        reflect: true,
      },
      canApplyNewTheme: {
        type: Boolean,
      },
      newThemeTmp: {
        type: Object,
      },
      applyingTheme: {
        type: Boolean,
        attribute: 'applying-theme',
        reflect: true,
      },
      main: {
        type: Boolean,
        attribute: true,
      },
      open: {
        type: Boolean,
      },
      // @todo add oldTheme with reflect:true to allow css transitions from [old-theme] to [theme] per theme
      // @todo fix header jumping from default theme to different theme when no sessionStorage is available - url based??
    }
  }

  constructor() {
    super()
    this.cartIcon = 'shop:cart'
    this.isScrolling = false
    this.intersectionObserverInitialized = false
    const cartUrlDefault = (BASE_URL ?? '/') + 'checkout/cart'
    this.cartUrl = window.checkout
      ? window.checkout.shoppingCartUrl || cartUrlDefault
      : cartUrlDefault

    this.boundHandleOpen = this.handleOpen.bind(this)
    this.boundHandleClose = this.handleClose.bind(this)
  }

  connectedCallback() {
    super.connectedCallback()

    if (this.main) {
      this.handleThemes(true)
    }
  }

  handleThemes(initial = false) {
    if (initial) {
      initializeHeaderThemes()

      // Set previously set theme (from previous page)
      const { theme, bgScrollIn } = getShopHeaderTheme()
      this.setTheme({ theme: theme || 'default', bgScrollIn: bgScrollIn || false })

      document.addEventListener('header-theme-changed', (e) => {
        const newTheme = e.detail.theme
        const newBgScroll = e.detail.bgScrollIn

        if (this.canApplyNewTheme) {
          this.setTheme({ theme: newTheme, bgScrollIn: newBgScroll })
          return
        }

        this.newThemeTmp = { theme: newTheme, bgScrollIn: newBgScroll }
      })
    }

    // only allow changes to header theme after next render - to allow transition from old to new value
    afterNextRender(this, () => {
      this.canApplyNewTheme = true
      if (!this.newThemeTmp) {
        // No new theme set, should return to default theme
        this.setTheme({ theme: 'default', bgScrollIn: false })
        // Update session storage - without firing event
        setShopHeaderTheme({ theme: 'default', bgScrollIn: false, fireEvent: false })

        return
      }
      const { theme, bgScrollIn } = this.newThemeTmp
      this.setTheme({ theme, bgScrollIn })
    })
  }

  setTheme(options /*: {theme: string, bgScrollIn: boolean} */) {
    const { theme, bgScrollIn } = options
    this.applyingTheme = true
    this.theme = theme
    this.bgScrollIn = bgScrollIn
    afterNextRender(this, () => {
      this.applyingTheme = false
    })
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties)

    afterNextRender(this, () => {
      this.__intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => this.handleHeaderScroll(entry))
        },
        { rootMargin: '1px' },
      )

      this.__intersectionObserver.observe(this.shadowRoot.querySelector('.scroll-detect-pixel'))

      this.addEventListeners()
    })
  }

  disconnectedCallback() {
    this.__intersectionObserver.disconnect()
    document.removeEventListener('open-mega-menu', this.boundHandleOpen)
    document.removeEventListener('close-mega-menu', this.boundHandleClose)
    super.disconnectedCallback()
  }

  addEventListeners() {
    afterNextRender(this, () => {
      this.shadowRoot.querySelector('header').addEventListener(
        'transitionend',
        debounce(() => {
          afterNextRender(this, () => {
            this.classList.remove('prepare-animation')
          })
        }, 400),
      )

      document.addEventListener('open-mega-menu', this.boundHandleOpen)
      document.addEventListener('close-mega-menu', this.boundHandleClose)
    })
  }

  handleHeaderScroll(entry) {
    if (this.intersectionObserverInitialized || !entry.isIntersecting) {
      this.classList.add('prepare-animation')
    }

    this.intersectionObserverInitialized = true

    afterNextRender(this, () => {
      if (this.disableIsScrolling) {
        return
      }
      this.isScrolling = !entry.isIntersecting
    })
  }

  handleOpenNav(event) {
    openMegaMenu(this)
  }

  handleCloseNav(event) {
    closeMegaMenu(this)
  }

  handleOpen() {
    this.open = true
  }

  handleClose() {
    this.open = false
  }
}
customElements.define('shop-header', ShopHeader)
