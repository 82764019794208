/*
  ~ Copyright © Reach Digital (https://www.reachdigital.io/)
  ~ See LICENSE.txt for license details.
  */
import { LitElement, html } from 'lit-element'
import '@polymer/paper-item/paper-item.js'
import { M2NavigationMixin } from '@reachdigital/m2-navigation/m2-navigation-mixin'

class ShopVerticalNavigation extends M2NavigationMixin(LitElement) {
  render() {
    return html`
      <style>
        :host {
          display: block;
          font-family: var(--font-family-base);
        }

        .top-menu {
          height: 100%;
          padding: 0;
          margin-top: 0;
          margin-bottom: var(--spacing-lg);
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }

        nav {
          background-color: #ffffff;
        }

        .item {
          display: inline-block;
          position: relative;
          height: 100%;
          padding: 0;
          box-sizing: border-box;
        }

        .item-label {
          padding: 0 0 0 var(--spacing-sm);
          position: relative;
          height: 100%;
          font-size: var(--font-size-base);
          font-family: var(--font-family-base);
          background: none !important;
          --dark-divider-opacity: 0;
        }

        .item:after,
        .item:before {
          display: block;
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          background: var(--base-separator);
          top: 0;
          width: 1px;
        }
        .item:after {
          background: var(--primary);
          width: 0;
        }
        .item[active]:after,
        .item:not([active]):hover:after {
          background: var(--primary);
          width: 2px;
        }

        .item:not([active]):hover:after {
          opacity: 0.5;
        }

        a {
          /*position: absolute;*/
          text-decoration: none;
          color: var(--primary);
        }
      </style>
      <nav class="menu">
        <slot hidden @slotchange=${this.handleSlotchange}></slot>
        ${this.renderNavigationTree(this.items, 0)}
      </nav>
    `
  }

  renderNavigationTree(items, level) {
    return html`
      <ul class="top-menu">
        ${items.map(
          (item) =>
            html`
              <li class="item ${item.class} level-${level}">
                <a
                  href="${item.link}"
                  title="${item.title}"
                  class="title ${item.children?.length > 0 ? 'haschild-link' : 'inner-link'}"
                >
                  <paper-item class="item-label">${item.title}</paper-item>
                </a>
              </li>
            `,
        )}
      </ul>
    `
  }
}

customElements.define('shop-vertical-navigation', ShopVerticalNavigation)
