/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */

import { LitElement, html } from 'lit-element'
import { addListener } from '@polymer/polymer/lib/utils/gestures'
import '@reachdigital/m2-container/m2-container'
import '@reachdigital/shop-product-data-tabs'
import { setShopHeaderTheme } from '@reachdigital/shop-header/headerTheme'
import { isMobile } from '@reachdigital/utils/is-mobile'
import '../../../common/sticky-drawer/sticky-drawer'
import '@reachdigital/shop-container/shop-container'
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status'

class CatalogProductView extends LitElement {
  static get properties() {
    return {
      isMobile: {
        type: Boolean,
      },
      galleryHeight: {
        type: Number,
      },
    }
  }

  constructor() {
    super()
    setShopHeaderTheme({ theme: 'transparent-dark', bgScrollIn: true })
  }

  connectedCallback() {
    super.connectedCallback()
    this.isMobile = isMobile((e) => (this.isMobile = e.matches))
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties)
    this.addEventListeners()
    afterNextRender(this, () => {
      this.__intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => this.handleIntersection(entry))
        },
        { rootMargin: '10000px 0px 100px 0px' },
      )
      this.__intersectionObserver.observe(
        this.shadowRoot.querySelector('.page-bottom-detection-pixel'),
      )
    })
  }

  addEventListeners() {
    this.addEventListener('media-gallery-size-changed', (e) => {
      this.galleryHeight = e.detail.boundingClientRect.height
    })
  }

  handleIntersection(entry) {
    this.dispatchEvent(
      new CustomEvent('catalog-product-view__bottom-intersection-changed', {
        detail: { isIntersecting: entry.isIntersecting },
        bubbles: true,
        composed: true,
      }),
    )
  }

  render() {
    return html`
      <style>
        :host {
          display: block;
          background: white;
          /*overflow:hidden;*/
          /*@todo fix overflow issue without overflow hidden due to position sticky of child */
        }

        .top-wrapper {
          display: flex;
          background: var(--base-fill);
        }
        .top-wrapper > m2-container {
          width: 100%;
        }
        @media (min-width: 768px) {
          .top-wrapper {
            background: linear-gradient(
              90deg,
              var(--base-fill) 0%,
              var(--base-fill) 50%,
              white 50%
            );
          }
        }
        .top {
          display: flex;
          width: 100%;
        }
        @media (max-width: 767px) {
          .top-wrapper {
            padding-top: var(--shop-header__height);
          }
          .top {
            flex-wrap: wrap;
            margin-left: 0;
            margin-right: 0;
            /*padding-top: var(--m2-container-padding);*/
            padding-top: 0;
          }
        }
        .top > * {
          box-sizing: border-box;
          padding-top: var(--spacing-lg);
          padding-bottom: var(--spacing-lg);
          --m2-media-gallery__top: var(--spacing-xl);
        }
        .top > .media {
          background: var(--base-fill);
          padding-right: var(--spacing-lg);
        }
        .top > .data {
          /* removing the 15px lineheight on shop-heading */
          padding-top: calc(var(--spacing-xl) - 15px);
          padding-left: var(--spacing-lg);
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .top > .media {
            padding-right: var(--spacing-md);
          }
          .top > .data {
            padding-left: var(--spacing-md);
          }
        }
        @media (max-width: 767px) {
          .top > .media {
            /*padding-left: 0;*/
            /*padding-right: 0;*/
            /*padding-bottom: var(--spacing-lg);*/

            z-index: 2;
            /*--stick-drawer__top-offset: 26px;*/
            padding-left: var(--m2-container-padding);
            padding-right: var(--m2-container-padding);
            padding-top: var(--m2-container-padding);
            /*padding-bottom: calc(var(--spacing-md) * 0.75);*/
            padding-bottom: calc(var(--spacing-md) * 0.5);
            margin-left: calc(var(--m2-container-padding) * -1);
            margin-right: calc(var(--m2-container-padding) * -1);
            max-width: none;
          }
          .media + .separator {
            height: calc(var(--spacing-md) * 0.5);
            padding: 0;
            width: 100%;
            background: var(--base-fill);
          }
          .top > .data {
            padding-left: var(--m2-container-padding);
            padding-right: var(--m2-container-padding);
          }
        }
        .media {
          flex: 0 0 61%;
        }
        .media-inner {
          position: relative;
          height: 100%;
        }
        .media-inner ::slotted([slot='media-gallery']) {
          height: 100%;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .media {
            flex: 1 0 42%;
          }
        }
        .data {
          flex: 0 0 39%;
        }
        @media (max-width: 767px) {
          .top > * {
            flex: 1 1 100%;
            max-width: 100%;
          }

          .data {
            margin: 0 calc(var(--m2-container-padding) * -1) 20px;
            max-width: none;
            width: auto;
            padding: var(--m2-container-padding);
            background: white;
          }
        }

        .tabs-wrapper m2-product-data-tabs {
          overflow: hidden;
          padding-bottom: 20px;
        }
        .tabs-wrapper {
          margin-top: var(--spacing-xl);
          padding-bottom: var(--spacing-xl);
        }
        @media (max-width: 767px) {
          .tabs-wrapper {
            margin-top: var(--spacing-md);
          }
        }
        .short-description-wrapper {
          margin-top: var(--spacing-xs);
          margin-bottom: var(--spacing-xs);
        }

        .short-description-wrapper ::slotted(*) {
          display: inline;
        }

        @media (min-width: 768px) {
          shop-container [slot='left'] {
            padding-right: var(--spacing-xl);
          }
          shop-container {
            overflow: hidden;
          }
          shop-container [slot='right'] {
            height: 100%;
          }
        }
        @media (max-width: 767px) {
          shop-container [slot='right'] {
            margin-left: calc(var(--m2-container-padding) * -1);
            margin-right: calc(var(--m2-container-padding) * -1);
            margin-top: var(--spacing-lg);
          }
        }

        sticky-drawer {
          /*top: -30px;*/
          /*top: calc(((var(--stick-drawer__shrink-pixels, 0) * -1) + var(--stick-drawer__top-offset, 0px)) - 50px);*/

          /* vp-style: 0px--15px @ 320px-414px */
          /*top: calc(51.1px + -15.96vw);*/
          /*@todo scale header */
          top: calc((51.1px + -15.96vw) - (65px - var(--shop-header__height)));
        }
      </style>
      <div class="top-wrapper">
        <m2-container>
          <div class="top">
            ${this.isMobile
              ? html`
                  <sticky-drawer
                    class="media"
                    shrink-percentage="35"
                    custom-height="${this.galleryHeight}"
                  >
                    <slot name="media-gallery"></slot>
                  </sticky-drawer>
                  <span class="separator"></span>
                `
              : html`
                  <div class="media">
                    <div class="media-inner">
                      <slot name="media-gallery"></slot>
                    </div>
                  </div>
                `}
            <div class="data">
              <slot name="title"></slot>
              <slot name="coming-soon"></slot>
              <!--<div class="short-description-wrapper">          -->
              <!--&lt;!&ndash;<slot name="short-description"></slot>&ndash;&gt;-->
              <!--</div>-->
              <slot name="add-to-cart"></slot>
            </div>
          </div>
        </m2-container>
      </div>

      <div class="tabs-wrapper">
        <shop-container equal-height stretch="right" size="medium" stretch-size="xlarge">
          <div slot="left">
            <shop-product-data-tabs>
              <slot name="tabs" slot="tabs"></slot>
              <slot name="tabpanels" slot="tabpanels"></slot>
            </shop-product-data-tabs>
          </div>
          <div slot="right">
            <slot name="atmosphere-image"></slot>
          </div>
        </shop-container>
      </div>
      <div class="page-bottom-detection-pixel"></div>
    `
  }
}
customElements.define('catalog-product-view', CatalogProductView)
