/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */
import { html, LitElement, css } from 'lit-element'
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js'
import { afterNextRender, beforeNextRender } from '@polymer/polymer/lib/utils/render-status'
import swiperStyles from 'swiper/dist/css/swiper.min.css'
import { Swiper, Navigation, Pagination, Scrollbar } from 'swiper/dist/js/swiper.esm.js'
import '@reachdigital/progress-indicator/progress-indicator'
import { isTablet } from '@reachdigital/shop-utils/isTablet'

Swiper.use([Navigation, Pagination, Scrollbar])
/**
 * `shop-swiper`
 * gallery of images on homepage
 */
class ShopSwiper extends LitElement {
  // language=CSS
  static styles = [
    css([swiperStyles]),
    css`
      :host {
        display: block;
        width: 100%;
        position: relative;

        --progress-indicator__text-color: var(
          --shop-swiper--progress-indicator__text-color,
          var(--base)
        );
        --progress-indicator__bullet-color: var(
          --shop-swiper--progress-indicator__bullet-color,
          var(--tertiary)
        );
        --progress-indicator__background-color: var(
          --shop-swiper--progress-indicator__bg-color,
          rgba(255, 255, 255, 0.2)
        );
      }

      .swiper-slide ::slotted(*) {
        display: block;
      }

      .swiper-scrollbar {
        width: calc(100% - var(--spacing-lg));
        height: 2px;
        background-color: rgba(214, 214, 214, 0.33);
        margin-bottom: var(--spacing-lg);
      }

      @media (max-width: 767px) {
        .swiper-scrollbar {
          margin-left: var(--m2-container-padding);
          margin-right: var(--m2-container-padding);
          width: calc(100% - (var(--m2-container-padding) * 2));
        }
      }

      .swiper-scrollbar-drag {
        background: var(--product-info-swiper__scrollbar-drag--background, var(--tertiary));
      }

      progress-indicator {
        position: absolute;
        top: 0;
        /* vp-style: -32px--80px @ 320px-1200px */
        left: calc(39.4px + -9.95vw);
      }

      @media (min-width: 1200px) {
        progress-indicator {
          left: -80px;
        }
      }
      @media (max-width: 1024px) {
        progress-indicator {
          left: 0;
          top: -50px;
        }
      }

      @media (min-width: 768px) {
        .swiper-button-prev,
        .swiper-button-next {
          width: 50%;
          height: 100%;
          top: 0;
          z-index: -1;
          background: none;
          margin-top: 0;
        }
        .swiper-button-prev {
          cursor: w-resize;
          left: 0;
        }
        .swiper-button-next {
          cursor: e-resize;
          right: 0;
        }
        .swiper-wrapper,
        .swiper-slide {
          pointer-events: none;
        }
        .swiper-container {
          line-height: 0;
        }
      }

      @media (max-width: 767px) {
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
        progress-indicator {
          left: var(--shop-swiper--progress-indicator__left, var(--m2-container-padding));
        }
      }
    `,
  ]

  static get properties() {
    return {
      currentSlide: {
        type: Number,
        reflect: true,
        attribute: 'current-slide',
      },
      orientation: {
        type: String,
        reflect: true,
        attribute: 'orientation',
      },
      options: {
        type: Object,
        reflect: true,
        attribute: 'options',
      },
      swiperScrollbar: {
        type: Boolean,
        reflect: true,
        attribute: 'swiper-scrollbar',
      },
      /**
       * @private
       */
      __dynamicSlots: {
        type: Array,
      },
    }
  }

  get options() {
    return this._options
  }

  set options(value) {
    const oldValue = this.options
    this._options = typeof value === 'string' ? JSON.parse(value) : value
    this.requestUpdate('options', oldValue)
  }

  get isTablet() {
    return this._isTablet
  }

  set isTablet(value) {
    const oldValue = this.isTablet

    if (!value) {
      this.orientation = 'vertical'
    } else {
      this.orientation = ''
    }
    this._isTablet = value

    this.requestUpdate('isTablet', oldValue)
  }

  constructor() {
    super()
    this.__lightDomElementsObserver = new FlattenedNodesObserver(this, () =>
      this._updateDynamicSlots(),
    )
    this.__dynamicSlots = []
    this.orientation = 'vertical'
  }

  connectedCallback() {
    super.connectedCallback()
    this.isTablet = isTablet((e) => (this.isTablet = e.matches))
  }

  disconnectedCallback() {
    this.__lightDomElementsObserver.disconnect()
    super.disconnectedCallback()
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties)

    this.swiperOptions = {
      speed: 400,
      spaceBetween: 20,
      navigation: {
        prevEl: this.shadowRoot.querySelector('.swiper-button-prev'),
        nextEl: this.shadowRoot.querySelector('.swiper-button-next'),
      },
      pagination: {
        el: this.shadowRoot.querySelector('.swiper-pagination'),
        clickable: true,
      },
      scrollbar: {
        el: this.shadowRoot.querySelector('.swiper-scrollbar'),
      },
      on: {
        init: () => {
          requestAnimationFrame(() => {
            this.currentSlide = this.swiper.activeIndex
          })
        },
        slideChange: () => {
          this.currentSlide = this.swiper.activeIndex
          //  Safari bug fix when activeIndex does not update
          if (!this.swiper.activeIndex && !this.swiper.previousIndex) {
            //  need afternextRender because the swiper-slide-active class will be set on the correct element after
            afterNextRender(this, () => {
              this.currentSlide = [
                ...this.shadowRoot.querySelector('.swiper-wrapper').children,
              ].indexOf(this.shadowRoot.querySelector('.swiper-slide-active'))
            })
          }
        },
      },
      ...this.options,
    }
  }

  _updateDynamicSlots() {
    const children = [...this.children].filter((child) => {
      return child.getAttribute('slot') === null || child.getAttribute('slot').startsWith('slide')
    })

    this.__dynamicSlots = children.map((child, index) => {
      return { name: `slide${index + 1}` }
    })

    // Setting [slot] attribute needs to happen after adding dynamic slots to ShadowRoot for Safari 12.2(+)
    // otherwise slot content won't be rendered
    // @todo check if this is still relevant in updates after Safari 12.2.
    beforeNextRender(this, () => {
      children.forEach((child, index) => {
        child.setAttribute('slot', `slide${index + 1}`)
      })
    })

    this.reInit()
  }

  reInit() {
    this.swiper && this.swiper.detachEvents()
    this.swiper && this.swiper.destroy(true, true)
    this._init()
  }

  _init() {
    beforeNextRender(this, (_) => {
      this.swiper = new Swiper(
        this.shadowRoot.querySelector('#swiperContainer'),
        this.swiperOptions,
      )
    })
  }

  render() {
    return html`
      ${this.swiperScrollbar ? html` <div class="swiper-scrollbar"></div> ` : html``}
      <!-- Slider main container -->
      <div class="swiper-container" id="swiperContainer">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          ${this.__dynamicSlots.map((slot) => {
            return html`
              <div class="swiper-slide">
                <slot name="${slot.name}"></slot>
              </div>
            `
          })}
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      ${!this.swiperScrollbar
        ? html`
            <progress-indicator
              current="${this.currentSlide}"
              end-count="${this.__dynamicSlots.length}"
              orientation="${this.orientation}"
            >
            </progress-indicator>
          `
        : html``}
    `
  }
}

window.customElements.define('shop-swiper', ShopSwiper)
