/*
 * Copyright © Reach Digital (https://www.reachdigital.io/)
 * See LICENSE.txt for license details.
 */
import { LitElement, html, css } from 'lit-element'
import '@reachdigital/m2-container'
import { setShopHeaderTheme } from '@reachdigital/shop-header/headerTheme'
import '@reachdigital/shop-heading'
import '@reachdigital/squiggly-line/squiggly-separator'

class CmsIndexIndex extends LitElement {
  static get styles() {
    // language=CSS
    return css`
      :host {
        position: relative;
        display: block;
        overflow: hidden;
        color: var(--base);
        background: var(--base-contrast);
      }

      squiggly-separator {
        z-index: 2;
      }
    `
  }

  constructor() {
    super()
    setShopHeaderTheme({ theme: 'transparent-light', bgScrollIn: true })
  }

  render() {
    // language=HTML
    return html`
      <slot name="hero-banner"></slot>

      <squiggly-separator></squiggly-separator>

      <div><slot name="homepage-section-one"></slot></div>
      <div><slot name="homepage-section-two"></slot></div>
      <div><slot name="homepage-section-three"></slot></div>
      <div><slot name="homepage-section-four"></slot></div>
      <div><slot name="homepage-section-five"></slot></div>
      <div><slot name="homepage-section-seven"></slot></div>
      <div><slot name="homepage-section-eight"></slot></div>
      <div><slot name="homepage-section-ten"></slot></div>
    `
  }
}
customElements.define('cms-index-index', CmsIndexIndex)
