/*
  ~ Copyright © Reach Digital (https://www.reachdigital.io/)
  ~ See LICENSE.txt for license details.
  */
/*
Element to retreive and style footer links
Expects ul>li or p elements in lightDOM
Add .heading class to make the first row of your list a heading
*/

import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { beforeNextRender } from '@polymer/polymer/lib/utils/render-status.js'
import { LERM } from '@reachdigital/lightdom-elems-retreiver-mixin/lightdom-elems-retreiver-mixin.js'

class ShopFooterLinks extends LERM(PolymerElement) {
  static get template() {
    return html`
      <style>
        :host {
          display: block;
          --__list-link-line-height: 3.36em;
        }
        @media (max-width: 767px) {
          :host {
            --__list-link-line-height: 2em;
          }
        }
        :host([no-filled-slots]) {
          display: none !important;
        }
        ul {
          padding-left: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        li {
          list-style: none;
          line-height: var(--m2-footer-links-list-item-line-height, inherit);
        }
        li strong a {
          color: var(--base);
          font-family: var(--font-family-alt);
          font-size: var(--font-size-base);
          line-height: 1em;
          font-weight: 500;
          letter-spacing: -0.33px;
          display: block;
          margin-bottom: var(--spacing-sm);
        }
        @media (max-width: 767px) {
          li strong a {
            font-size: var(--extra-large__font-size);
            margin-bottom: calc(var(--spacing-md) * 0.8);
          }
        }
        li strong a:hover {
          color: var(--base);
        }
        li a {
          color: var(--light-grey-faded);
          font-family: var(--font-family-base);
          font-size: var(--tiny__font-size);
          line-height: var(--tiny__line-height);
          letter-spacing: -0.1px;
          text-decoration: none;
          -webkit-font-smoothing: antialiased;
          margin-bottom: var(--spacing-xs);
          display: block;
        }
        @media (max-width: 767px) {
          li a {
            margin-bottom: var(--spacing-sm);
            display: inline-block;
          }
        }
        li:last-child a {
          /* Compensate for link line-height */
          margin-bottom: calc(
            var(--m2-footer-links-list-link-line-height, var(--__list-link-line-height)) * -0.5 +
              1em
          );
        }
        li a:hover {
          color: var(--m2-footer-links-list-link-hover-color, var(--primary));
          text-decoration: var(--m2-footer-links-list-link-hover-text-decoration, none);
        }

        .heading {
          font-size: var(--m2-footer-links-heading-font-size, 2.1rem);
          margin-bottom: 2rem;
          display: block;
          @apply --m2-footer-links-heading;
        }
        p {
          margin-top: 0;
          margin-bottom: 0;
        }
        p a {
          color: var(--m2-footer-links-text-link-color, inherit);
          text-decoration: var(--m2-footer-links-text-link-text-decoration, underline);
        }
        p a:hover {
          color: var(--m2-footer-links-text-link-hover-color, var(--primary));
          text-decoration: var(--m2-footer-links-text-link-hover-text-decoration, underline);
        }
        .wrapper {
          margin-left: calc(var(--m2-footer-links-item-spacing, var(--spacing-md)) * -1);
          margin-right: calc(var(--m2-footer-links-item-spacing, var(--spacing-md)) * -1);
        }
        .wrapper > * {
          margin-left: var(--m2-footer-links-item-spacing, var(--spacing-md));
          margin-right: var(--m2-footer-links-item-spacing, var(--spacing-md));
        }
        img {
          @apply --m2-footer-links-img;
        }
      </style>
      <div class="wrapper"></div>
    `
  }

  static get is() {
    return 'shop-footer-links'
  }

  static get properties() {
    return {
      targets: {
        value: [
          {
            key: 'links',
            selector: '*',
          },
        ],
      },
    }
  }

  lightDomElemsChanged(elems, oldElems) {
    // @ts-ignore
    const wrapper = this.shadowRoot.querySelector('.wrapper')
    beforeNextRender(this, () => {
      wrapper.innerHTML = ''
      if (elems.links[0]) {
        const footerContent = elems.links[0].cloneNode(true)
        wrapper.append(footerContent)
      }
    })
  }
}
customElements.define(ShopFooterLinks.is, ShopFooterLinks)
